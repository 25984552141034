const generateRandomColors = () => {
  let s = '#';
  for (let i = 0; i < 3; i++) {
    let c = Math.floor(Math.random() * 255).toString(16);
    if (c.length === 1) {
      c = `${0}c`;
    }
    s += c;
  }

  return s;
};

export default generateRandomColors;
