import * as React from 'react';
import { useSpring, animated } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import styled from 'styled-components';

const AnimatedContainer = styled(animated.div)<{ $maxWidth?: number }>`
  display: flex;
  overflow: hidden;
  max-width: ${(props) => `${props.$maxWidth}px` || '500px'};
  padding: 8px 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    cursor: default;
  }
`;

interface Props {
  children: React.ReactNode;
  maxWidth?: number;
}

const HorizontalTouchScroll: React.FC<Props> = ({ children, maxWidth }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isDragging = React.useRef(false);
  const [{ x }, set] = useSpring(() => ({ x: 0 }));
  const bind = useGesture(
    {
      onDrag({ down, movement: [x], first, last, vxvy: [vx] }) {
        // console.log('onDrag with movement', { x, first, last, vx, down });
        if (first) isDragging.current = true;
        if (last) setTimeout(() => (isDragging.current = false), 0);
        set.start({ x: -x, immediate: down });
      },
      onClickCapture(ev) {
        // if (isDragging.current) {
        //   ev.stopPropagation();
        // }
      },
      onWheelStart() {
        // Stop any user-land scroll animation from confcliting with the browser
        set.stop();
      },
    },
    {
      drag: {
        axis: 'x',
        filterTaps: true,
        initial() {
          // @ts-ignore
          return [-ref.current?.scrollLeft, 0];
        },
      },
    },
  );

  return (
    <AnimatedContainer ref={ref} scrollLeft={x} {...bind()} $maxWidth={maxWidth}>
      {children}
    </AnimatedContainer>
  );
};

export default HorizontalTouchScroll;
