import React, { useState } from 'react';
import styled from 'styled-components';
import shadow from '../helpers/shadow';

const SlideContainer = styled.div`
  width: 100%;
`;

const Slider = styled.input<{ $disabled?: boolean }>`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  background: #aabfc5; /* Grey background */
  border-radius: 5px;
  outline: none; /* Remove outline */

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    border-radius: 50%;
    background: #3b1952; /* Green background */
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')}; /* Cursor on hover */

    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
    &:hover {
      opacity: ${(props) => (props.$disabled ? 1 : 0.85)};
    }
  }

  &::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    border-radius: 50%;
    background: #04aa6d; /* Green background */
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};

    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
    &:hover {
      opacity: ${(props) => (props.$disabled ? 1 : 0.85)};
    }
  }
`;

interface Props {
  minValue: number;
  maxValue: number;
  defaultValue: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

const HorizontalSlider: React.FC<Props> = ({ minValue, maxValue, defaultValue, onChange, disabled }) => {
  const [rangeValue, setRangeValue] = useState(defaultValue);

  return (
    <SlideContainer style={{ opacity: disabled ? 0.6 : 1 }}>
      <Slider
        disabled={disabled}
        $disabled={disabled}
        onChange={(event) => {
          setRangeValue(+event.target.value);
          onChange(+event.target.value);
        }}
        type='range'
        defaultValue={defaultValue}
        min={minValue}
        max={maxValue}
      />
    </SlideContainer>
  );
};

export default HorizontalSlider;
