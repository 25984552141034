import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import IconContainer from './IconContainer';
import MainContainer from './MainContainer';
import { IconType } from 'react-icons';

import * as AntDesignIcons from 'react-icons/ai';
import * as BootstrapIcons from 'react-icons/bs';
import * as BoxIcons from 'react-icons/bi';
import * as CircumIcons from 'react-icons/ci';
import * as DevIcons from 'react-icons/di';
import * as FlatColorIcons from 'react-icons/fc';
import * as FontAwesome5 from 'react-icons/fa';
import * as FontAwesome6 from 'react-icons/fa6';
import * as GameIcons from 'react-icons/gi';
import * as GithubOcticons from 'react-icons/go';
import * as GrommetIcons from 'react-icons/gr';
import * as IcoMoonFree from 'react-icons/im';
import * as Icons8LineAwesome from 'react-icons/lia';
import * as IonIcons from 'react-icons/io';
import * as IonIcons5 from 'react-icons/io5';
import * as MaterialDesignIcons from 'react-icons/md';
import * as PhosphorIcons from 'react-icons/pi';
import * as RemixIcons from 'react-icons/ri';
import * as SimpleIcons from 'react-icons/si';
import * as SimpleLineIcons from 'react-icons/sl';
import * as ThemifyIcons from 'react-icons/tfi';
import * as Typicons from 'react-icons/ti';
import * as VSCodeIcons from 'react-icons/vsc';
import * as WeatherIcons from 'react-icons/wi';
import { AiOutlineCode } from 'react-icons/ai';

const IconSets = {
  AntDesignIcons,
  BootstrapIcons,
  BoxIcons,
  CircumIcons,
  DevIcons,
  FlatColorIcons,
  FontAwesome5,
  FontAwesome6,
  GameIcons,
  GithubOcticons,
  GrommetIcons,
  IcoMoonFree,
  Icons8LineAwesome,
  IonIcons,
  IonIcons5,
  MaterialDesignIcons,
  PhosphorIcons,
  RemixIcons,
  SimpleIcons,
  SimpleLineIcons,
  ThemifyIcons,
  Typicons,
  VSCodeIcons,
  WeatherIcons,
} as { [key1: string]: { [key2: string]: IconType } };

const iconSetsFlatArr = Object.keys(IconSets)
  .map((pn) => {
    return Object.keys(IconSets[pn]).map((x) => IconSets[pn][x]);
  })
  .flat()
  .map((ic: IconType) => ({ name: ic.toString(), func: ic }));

const Background = styled.div`
  background-color: #f9fdfe;
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: row;
`;

const LogoScreen: React.FC<{}> = () => {
  const [activeIconName, setActiveIconName] = useState<IconType>(AiOutlineCode);

  const iconSetsFlat = useMemo(() => {
    return Object.keys(IconSets)
      .map((pn) => {
        return Object.keys(IconSets[pn]).map((ic) => IconSets[pn][ic]);
      })
      .flat();
  }, []);

  // const activeIcon = useMemo((): IconType | null => {
  //   if (activeIconName !== '') {
  //     console.log(`activeIconName: ${activeIconName}`);
  //     console.log(iconSetsFlat);
  //     console.log('fixed arr', iconSetsFlatArr);
  //     console.log(`Icon found: ${iconSetsFlat.find((x) => x.name === activeIconName)}`);
  //     return iconSetsFlat.find((x) => x.name === activeIconName) || null;
  //   }
  //   return null;
  // }, [activeIconName, iconSetsFlat]);

  return (
    <Background>
      <IconContainer
        iconSets={IconSets}
        setActiveIcon={(icon: IconType) => {
          setActiveIconName(icon);
        }}
      />
      <MainContainer activeIcon={activeIconName} />
    </Background>
  );
};

export default LogoScreen;
