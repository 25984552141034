import React, { Key, useCallback, useState } from 'react';
import ColorPickerPopup from '../../../components/ColorPickerPopup';
import styled from 'styled-components';
import shadow from '../../../helpers/shadow';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const PercentageInputBox = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  height: 35px;
  background-color: #f9fdfe;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  align-items: center;

  padding-right: 8px;
  margin-left: 16px;
  box-shadow: ${shadow.light};

  opacity: ${(props) => (props.$disabled ? 0.6 : 1)};

  &:hover {
    cursor: ${(props) => (props.$disabled ? 'default' : 'text')};
  }
`;

const PercentageInput = styled.input`
  height: 35px;
  text-align: right;
  width: 25px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  padding: 0 2px 0 8px;
  background-color: transparent;

  &:focus-visible {
    outline: none;
  }
`;

interface Props {
  gradientDisabled?: boolean;
  defaultColor?: string;
  handleChange: (color: string, offset: string) => void;
  key: Key;
}

const ColorGradientPickerRow: React.FC<Props> = ({ key, defaultColor, gradientDisabled, handleChange }) => {
  const [percentageInput, setPercentageInput] = useState('100');
  const [latestGradientValue, setLatestGradientValue] = useState('100');
  const [activeColor, setActiveColor] = useState(defaultColor || '#ffffff');

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (+e.target.value >= 0 && +e.target.value <= 100 && e.target.value.length <= 3) {
        setPercentageInput(e.target.value);
        handleChange(activeColor, e.target.value);
      }
    },
    [activeColor, handleChange],
  );

  const handleEmptySubmit = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        setPercentageInput(latestGradientValue);
        return;
      }

      if (e.target.value === '00' || e.target.value === '000') {
        setPercentageInput('0');
        setLatestGradientValue('0');
      } else {
        setLatestGradientValue(e.target.value);
      }
    },
    [latestGradientValue],
  );

  return (
    <Row key={key}>
      <ColorPickerPopup
        key={key}
        defaultColor={defaultColor}
        onChange={(color) => {
          setActiveColor(color);
          handleChange(color, percentageInput);
        }}
      />
      <PercentageInputBox key={key} $disabled={gradientDisabled}>
        <PercentageInput
          key={key}
          disabled={gradientDisabled}
          value={percentageInput}
          onChange={handleInputChange}
          onBlur={handleEmptySubmit}
        />
        <span>%</span>
      </PercentageInputBox>
    </Row>
  );
};

export default ColorGradientPickerRow;
