import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import shadow from '../../helpers/shadow';
import HorizontalTouchScroll from '../../components/HorizontalTouchScroll';

import { IconType } from 'react-icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e1eaed;
  flex: 2;
  padding: 32px 24px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #1a0429;
  font-size: 32px;
  margin: 0 0 8px 0;
`;

const SearchContainer = styled.input`
  width: 100%;
  height: 35px;
  background-color: #f9fdfe;
  border-width: 0;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  padding-left: 8px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  margin-bottom: 24px;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
    color: #aabfc5;
  }

  box-shadow: ${shadow.light};
`;

const IconCard = styled.div`
  width: 100%;
  flex: 1;
  overflow: hidden;

  background-color: #f9fdfe;
  border-radius: 8px;

  box-shadow: ${shadow.light};
`;

const IconSetCard = styled.div<{ $selected?: boolean }>`
  flex: 0 0 auto;
  vertical-align: center;
  text-align: center;
  line-height: 25px;
  height: 25px;
  padding: 4px 8px;
  margin: 0 6px;
  cursor: pointer;

  font-weight: bold;

  background-color: ${(props) => (props.$selected ? '#AABFC5' : '#3B1952')};
  color: ${(props) => (props.$selected ? '#1A0429' : '#F9FDFE')};

  box-shadow: ${shadow.light};
  border-radius: 8px;
  transition:
    opacity 120ms ease-out,
    color 120ms ease-out,
    background-color 120ms ease-out;
  -moz-transition:
    opacity 120ms ease-out,
    color 120ms ease-out,
    background-color 120ms ease-out;
  -webkit-transition:
    opacity 120ms ease-out,
    color 120ms ease-out,
    background-color 120ms ease-out;
  -o-transition:
    opacity 120ms ease-out,
    color 120ms ease-out,
    background-color 120ms ease-out;

  &:hover {
    opacity: 0.85;
  }
`;

const IconDisplayContainer = styled.div<{ $maxHeight?: number; $parentWidth?: number }>`
  display: grid;
  grid-template-columns: ${(props) => '1fr '.repeat(props.$parentWidth ? props.$parentWidth / 75 : 4)};
  padding: 12px;
  overflow: scroll;
  overflow-x: hidden;
  max-height: ${(props) => (props.$maxHeight ? `${props.$maxHeight - 80}px` : '600px')};
`;

const IconDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  cursor: pointer;
`;

interface Props {
  setActiveIcon: (icon: IconType) => void;
  iconSets: { [key1: string]: { [key2: string]: IconType } };
}

const IconContainer: React.FC<Props> = ({ setActiveIcon, iconSets }) => {
  const [selectedName, setSelectedName] = useState('');
  const [searchString, setSearchString] = useState('');
  const iconCardRef = useRef<HTMLDivElement>(null);
  const [iconCardDimensions, setIconCardDimensions] = useState({ width: 0, height: 0 });

  const onSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value.toLowerCase());
  }, []);

  useLayoutEffect(() => {
    if (iconCardRef.current) {
      setIconCardDimensions({ height: iconCardRef.current.clientHeight, width: iconCardRef.current.clientWidth });
    }
  }, []);

  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 8 }}>
        <img src={'/logoboiler192x192.png'} height={40} width={40} alt={'logo'} style={{ marginRight: 12 }} />
        <Title>LogoBoiler</Title>
      </div>
      <SearchContainer placeholder={'Search..'} onChange={onSearchInputChange} />
      <IconCard ref={iconCardRef}>
        <HorizontalTouchScroll maxWidth={iconCardDimensions.width}>
          {Object.keys(iconSets).map((name: string, index) => (
            <IconSetCard key={name} $selected={selectedName === name} onClick={() => setSelectedName(name)}>
              {name}
            </IconSetCard>
          ))}
        </HorizontalTouchScroll>
        <IconDisplayContainer $maxHeight={iconCardDimensions.height} $parentWidth={iconCardDimensions.width}>
          {Object.keys(iconSets[selectedName] || {}).map((x) => {
            if (searchString.length > 2 && !x.toLowerCase().includes(searchString)) {
              return null;
            }
            return (
              <IconDisplay
                key={x}
                onClick={() => {
                  setActiveIcon(iconSets[selectedName][x]);
                }}
              >
                {iconSets[selectedName][x]({ size: 40 })}
              </IconDisplay>
            );
          })}
        </IconDisplayContainer>
      </IconCard>
    </Container>
  );
};

export default IconContainer;
