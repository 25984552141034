import React, { Key, useState } from 'react';
import { SketchPicker } from 'react-color';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import shadow from '../helpers/shadow';
import generateRandomColor from '../helpers/generateRandomColor';

const ColorInputButton = styled.div`
  height: 35px;
  background-color: #f9fdfe;
  border-width: 0;
  border-radius: 8px;
  padding: 0 8px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:focus-visible {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  box-shadow: ${shadow.light};
`;

const ColorPreviewBox = styled.div<{ $bgColor: string }>`
  padding: 8px;
  border-radius: 4px;
  border: solid 0.25pt #666;
  background-color: ${(props) => props.$bgColor};
`;

const ColorLabel = styled.div`
  margin-left: 6px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

interface Props {
  onChange: (color: string) => void;
  defaultColor?: string;
  key: Key;
}

const ColorPickerPopup: React.FC<Props> = ({ key, onChange, defaultColor }) => {
  const [activeColor, setActiveColor] = useState(defaultColor || '#eeaacc');

  return (
    <Popup
      key={key}
      position={'top center'}
      trigger={() => {
        return (
          <ColorInputButton>
            <ColorPreviewBox $bgColor={activeColor} />
            <ColorLabel>{activeColor.toUpperCase()}</ColorLabel>
          </ColorInputButton>
        );
      }}
    >
      <SketchPicker
        disableAlpha={true}
        color={activeColor}
        onChange={(color, event) => {
          setActiveColor(color.hex);
          onChange(color.hex);
        }}
      />
    </Popup>
  );
};

export default ColorPickerPopup;
