import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons';
import { SketchPicker } from 'react-color';
import HorizontalSlider from '../../components/HorizontalSlider';
import shadow from '../../helpers/shadow';
import Popup from 'reactjs-popup';
import ColorPickerPopup from '../../components/ColorPickerPopup';
import ColorGradientPickerRow from './components/ColorGradientPickerRow';
import generateRandomColor from '../../helpers/generateRandomColor';
import generateRandomID from '../../helpers/generateRandomID';
import { toPng, toSvg } from 'html-to-image';

const Container = styled.div`
  background-color: #f9fdfe;
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left-width: 1px;
  border-left-color: #c9d8dc;
  border-left-style: solid;

  border-right-width: 1px;
  border-right-color: #c9d8dc;
  border-right-style: solid;
`;

const LogoContainer = styled.div<{ $borderRadius: number; $bgColor: string }>`
  background: ${(props) => props.$bgColor};
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  border-radius: ${(props) => `${props.$borderRadius}px`};
`;

const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ControlsContainer = styled.div`
  flex: 1;
  padding: 8px 32px;
`;

const GaugeContainer = styled.div``;
const GaugeTitle = styled.h3``;
const GaugeSubtitle = styled.h5`
margin-top: 8px;
margin-bottom: 8px`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const AddMoreColorsButton = styled.div`
  height: 35px;
  background-color: #aabfc5;
  border-width: 0;
  border-radius: 8px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.85;
  transition: opacity 125ms ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  box-shadow: ${shadow.light};
`;

const AddMoreColorsText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #555c62;
`;

const VerticalDivider = styled.div`
  background-color: #aabfc5;
  margin-top: 2%;
  height: 98%;
  width: 1px;
`;

const HorizontalDivider = styled.div`
  background-color: #aabfc5;
  width: 98%;
  height: 1px;
`;

const RemoveColorButton = styled.div`
  background-color: #ff0000;
  margin-right: 8px;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  opacity: 0.7;
  line-height: 22px;
  text-align: center;
  vertical-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: bolder;

  transition: opacity 125ms ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const ExportButton = styled.div`
  border-radius: 8px;
  background-color: #3b1952;
  cursor: pointer;
  padding: 8px 16px;
  opacity: 0.9;
  transition: opacity 125ms ease-in-out;

  &:hover {
    opacity: 1;
  }
    
    box-shadow: ${shadow.light};
`;

const ExportText = styled.span`
  color: #f9fdfe;
  font-weight: bold;
  font-size: 16px;
`;

interface GradientColor {
  id: number;
  color: string; // Hex code
  gradient: string; // Percentage
}

interface Props {
  activeIcon: IconType | null;
}

const MainContainer: React.FC<Props> = ({ activeIcon }) => {
  const logoRef = useRef<HTMLDivElement>(null);

  const [bgBorderRadius, setBgBorderRadius] = useState(60);
  const [bgGradientDeg, setBgGradientDeg] = useState(0);
  const [bgColorArr, setBgColorArr] = useState<GradientColor[]>([
    {
      id: generateRandomID(),
      color: '#3b1952',
      gradient: '100',
    },
  ]);

  //@ts-ignore
  const [Icon, setIcon] = useState(activeIcon);
  //@ts-ignore
  const [oldActiveIcon, setOldActiveIcon] = useState(activeIcon);
  const [iconSize, setIconSize] = useState(240);
  const [iconGradientDeg, setIconGradiantDeg] = useState(0);
  const [iconColorArr, setIconColorArr] = useState<GradientColor[]>([
    {
      id: generateRandomID(),
      color: '#ffffff',
      gradient: '100',
    },
  ]);

  const onExportPngClicked = useCallback(() => {
    if (logoRef.current === null) {
      return;
    }

    toPng(logoRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'logoboiler.com.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [logoRef]);

  const onExportSvgClicked = useCallback(() => {
    if (logoRef.current === null) {
      return;
    }

    toSvg(logoRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'logoboiler.com.svg';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [logoRef]);

  const handleIconColorChange = useCallback(
    (id: number, color: string, offset: string) => {
      const newArr = iconColorArr.map((x) => {
        if (id === x.id) {
          return { id: x.id, color, gradient: offset };
        } else {
          return x;
        }
      });
      setIconColorArr(newArr);
    },
    [iconColorArr],
  );

  const handleBgColorChange = useCallback(
    (id: number, color: string, offset: string) => {
      const newArr = bgColorArr.map((x) => {
        if (id === x.id) {
          return { id: x.id, color, gradient: offset };
        } else {
          return x;
        }
      });
      setBgColorArr(newArr);
    },
    [bgColorArr],
  );

  const removeIconColor = useCallback(
    (id: number) => {
      let newArr: GradientColor[] = [];
      iconColorArr.forEach((x) => {
        if (x.id !== id) {
          newArr.push(x);
        }
      });

      setIconColorArr(newArr);
    },
    [iconColorArr],
  );

  const removeBgColor = useCallback(
    (id: number) => {
      let newArr: GradientColor[] = [];
      bgColorArr.forEach((x) => {
        if (x.id !== id) {
          newArr.push(x);
        }
      });

      setBgColorArr(newArr);
    },
    [bgColorArr],
  );

  const backgroundGradientString = useMemo(() => {
    let s = `linear-gradient(${bgGradientDeg}deg,`;
    bgColorArr.forEach((x) => {
      s += `${x.color} ${x.gradient}%,`;
    });
    s = `${s.substring(0, s.length - 1)})`;
    return s;
  }, [bgColorArr, bgGradientDeg]);

  const isIconGradientDisabled = useMemo(() => iconColorArr.length <= 1, [iconColorArr]);
  const isBgGradientDisabled = useMemo(() => bgColorArr.length <= 1, [bgColorArr]);

  useEffect(() => {
    let swapIcon = false;
    if (activeIcon !== oldActiveIcon) {
      //@ts-ignore
      setOldActiveIcon(activeIcon);
      swapIcon = true;
    }
    // @ts-ignore
    setIcon(React.cloneElement(swapIcon ? activeIcon : Icon, {
      size: iconSize,
      fill: iconColorArr.length > 1 ? 'url(#icon-gradient)' : iconColorArr[0].color,
      stroke: iconColorArr.length > 1 ? 'url(#icon-gradient)' : iconColorArr[0].color,
    }));
  }, [iconSize, iconColorArr, activeIcon]);

  return (
    <Container>
      <div style={{ position: 'absolute', top: 48, right: 32, display: 'flex', flexDirection: 'column' }}>
        <ExportButton onClick={onExportPngClicked}>
          <ExportText style={{ color: '#F9FDFE', fontWeight: 'bold', fontSize: 16 }}>Export to PNG</ExportText>
        </ExportButton>

        <ExportButton
          style={{
            marginTop: 12,
          }}
          onClick={onExportSvgClicked}
        >
          <ExportText style={{ color: '#F9FDFE', fontWeight: 'bold', fontSize: 16 }}>Export to SVG</ExportText>
        </ExportButton>
      </div>

      <LogoContainer
        ref={logoRef}
        $bgColor={bgColorArr.length > 1 ? backgroundGradientString : bgColorArr[0].color}
        $borderRadius={bgBorderRadius}
      >
        <>
        <svg width='0' height='0'>
          <linearGradient id='icon-gradient' gradientTransform={`rotate(${iconGradientDeg})`}>
            {iconColorArr.map((n) => (
              <stop stopColor={n.color} offset={`${+n.gradient > 0 ? n.gradient : '0'}%`} />
            ))}
          </linearGradient>
        </svg>
        {Icon
          ? Icon
          : null}
      </>
      </LogoContainer>

      <ControlsRow>
        <ControlsContainer>
          <GaugeTitle>Icon</GaugeTitle>
          <HorizontalDivider />
          <GaugeContainer>
            <Row>
              <Column>
                <GaugeSubtitle>Color</GaugeSubtitle>
                {iconColorArr.map((n, i) => (
                  <Row style={{ margin: 0, alignItems: 'center' }}>
                    {i !== 0 && <RemoveColorButton onClick={() => removeIconColor(n.id)}> - </RemoveColorButton>}
                    <ColorGradientPickerRow
                      key={n.id}
                      defaultColor={n.color}
                      handleChange={(color, offset) => {
                        handleIconColorChange(n.id, color, offset);
                      }}
                      gradientDisabled={isIconGradientDisabled}
                    />
                  </Row>
                ))}
                <Row>
                  <AddMoreColorsButton
                    onClick={() =>
                      setIconColorArr([
                        ...iconColorArr,
                        { id: generateRandomID(), color: generateRandomColor(), gradient: '100' },
                      ])
                    }
                  >
                    <AddMoreColorsText>+ Add more colors</AddMoreColorsText>
                  </AddMoreColorsButton>
                </Row>
              </Column>
              <Column style={{ width: '100%', marginLeft: 16 }}>
                <GaugeSubtitle style={{ opacity: isIconGradientDisabled ? 0.7 : 1 }}>Gradient angle</GaugeSubtitle>
                <HorizontalSlider
                  minValue={0}
                  maxValue={360}
                  defaultValue={0}
                  onChange={setIconGradiantDeg}
                  disabled={isIconGradientDisabled}
                />
              </Column>
            </Row>
          </GaugeContainer>
          <GaugeContainer>
            <GaugeSubtitle>Icon size</GaugeSubtitle>
            <HorizontalSlider minValue={0} maxValue={300} defaultValue={180} onChange={(value) => setIconSize(value)} />
          </GaugeContainer>
        </ControlsContainer>
        <VerticalDivider />
        <ControlsContainer>
          <GaugeTitle>Background</GaugeTitle>
          <HorizontalDivider />
          <GaugeContainer>
            <Row>
              <Column>
                <GaugeSubtitle>Color</GaugeSubtitle>
                {bgColorArr.map((n, i) => (
                  <Row style={{ margin: 0, alignItems: 'center' }}>
                    {i !== 0 && <RemoveColorButton onClick={() => removeBgColor(n.id)}> - </RemoveColorButton>}
                    <ColorGradientPickerRow
                      key={n.id}
                      defaultColor={n.color}
                      handleChange={(color, offset) => {
                        handleBgColorChange(n.id, color, offset);
                      }}
                      gradientDisabled={isBgGradientDisabled}
                    />
                  </Row>
                ))}
                <Row>
                  <AddMoreColorsButton
                    onClick={() =>
                      setBgColorArr([
                        ...bgColorArr,
                        { id: generateRandomID(), color: generateRandomColor(), gradient: '100' },
                      ])
                    }
                  >
                    <AddMoreColorsText>+ Add more colors</AddMoreColorsText>
                  </AddMoreColorsButton>
                </Row>
              </Column>
              <Column style={{ width: '100%', marginLeft: 16 }}>
                <GaugeSubtitle style={{ opacity: isBgGradientDisabled ? 0.7 : 1 }}>Gradient angle</GaugeSubtitle>
                <HorizontalSlider
                  minValue={0}
                  maxValue={360}
                  defaultValue={0}
                  onChange={setBgGradientDeg}
                  disabled={isBgGradientDisabled}
                />
              </Column>
            </Row>
          </GaugeContainer>
          <GaugeContainer>
            <GaugeSubtitle>Roundness</GaugeSubtitle>
            <HorizontalSlider
              minValue={0}
              maxValue={200}
              defaultValue={60}
              onChange={(value) => setBgBorderRadius(value)}
            />
          </GaugeContainer>
        </ControlsContainer>
      </ControlsRow>
    </Container>
  );
};

export default MainContainer;
